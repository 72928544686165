import React from "react";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../../website-assets/images/logo.svg";
import { updateProfile } from "../services/api";

const Profile = () => {
  const notify = () =>
    toast("Profile Updated!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  const [errors, setErrors] = useState({});
  const [username, setUserName] = useState(sessionStorage.getItem("user_name"));
  const [email, setEmail] = useState(sessionStorage.getItem("user_email"));
  const [profilePicture, setProfilePicture] = useState(null); 
  const [password, setPassword] = useState();

  const handleSubmit = async (e) => {
    const token = sessionStorage.getItem("token");
    var formData = new FormData();
    formData.append("user_name", username);
    formData.append("email", email);
    formData.append("password", password);
    if (profilePicture) {
      formData.append("profile_picture", profilePicture); 
    }
    const response = await updateProfile(token, formData);
    sessionStorage.setItem('user_name',response.data.name);
    sessionStorage.setItem('email', response.data.email);
    const profileImage=`${process.env.REACT_APP_API_BASE_URL}/uploads/${response.data.profile_image}`;
    sessionStorage.setItem('profile_image',profileImage);
    notify();
    console.log(response);
  };

  const handleNameChange = (e) => {
    setUserName(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleProfilePictureChange = (e) => {
    setProfilePicture(e.target.files[0]);
  };

  return (
    <div className="login-register">
      <ToastContainer />
      <em class="over position-absolute bg-black opacity-75 bottom-0 top-0 end-0 start-0"></em>
      <div class="container vh-100 position-relative z-1">
        <div class="row align-items-center h-100">
          <div class="col-lg-2 col-md-1"></div>
          <div class="col-lg-8 col-md-10">
            <section class="bg-dark-ben rounded-3 overflow-hidden shadow p-4 my-4">
              <div class="row">
                <div class="col-md-12">
                  <div class="text-center my-4">
                    <a href="javascript:;">
                      <img
                        src={Logo}
                        alt="Logo"
                        class="img-fluid"
                        width="130"
                      />
                    </a>
                    <br />
                    <h1 class="h3 fw-bold text-white py-4">Manage Profile</h1>
                  </div>
                  {/* <form > */}
                    {errors.apiError && (
                      <span className="text-danger">{errors.apiError}</span>
                    )}
                    {errors.name && (
                      <span className="text-danger">{errors.name}</span>
                    )}
                    <div class="input-group mb-4 mt-2 bg-light-ben">
                      <input
                        type="text"
                        class="shadow-none form-control rounded-0 bg-light-ben border-light-ben text-light-ben"
                        name="name"
                        placeholder="Enter Your Name*"
                        value={username}
                        onChange={handleNameChange}
                      />
                    </div>
                    {errors.email && (
                      <span className="text-danger">{errors.email}</span>
                    )}
                    <div class="input-group mb-4 mt-2 bg-light-ben">
                      <input
                        type="text"
                        class="shadow-none form-control rounded-0 bg-light-ben border-light-ben text-light-ben"
                        name="email"
                        placeholder="Enter Your Email*"
                        value={email}
                        onChange={handleEmailChange}
                      />
                    </div>
                    {errors.password && (
                      <span className="text-danger">{errors.password}</span>
                    )}
                    <div class="input-group mb-4 mt-2 bg-light-ben">
                      <input
                        type="password"
                        class="shadow-none form-control rounded-0 bg-light-ben border-light-ben text-light-ben"
                        name="password"
                        placeholder="Enter Your Password*"
                        value={password}
                        onChange={handlePasswordChange}
                      />
                    </div>
                    {errors.c_password && (
                      <span className="text-danger">{errors.c_password}</span>
                    )}
                    <div className="input-group mb-4 mt-2 bg-light-ben">
                    <input
                      type="file"
                      className="shadow-none form-control rounded-0 bg-light-ben border-light-ben text-light-ben"
                      name="profile_picture"
                      onChange={handleProfilePictureChange}
                    />
                  </div>

                    <div class="mb-4 mt-2">
                      <button
                        name="btn"
                        class="btn btn-primary-ben w-100 rounded-0 fw-bold p-3 text-uppercase"
                        onClick={handleSubmit}
                      >
                        Update Profile
                      </button>
                    </div>
                  {/* </form> */}
                </div>
              </div>
            </section>
          </div>
          <div class="col-lg-2 col-md-1"></div>
        </div>
      </div>
    </div>
  );
};
export default Profile;
