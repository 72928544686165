import React, { useEffect, useState } from "react";
import { osrsProducts, rs3Products } from "../services/api";
import { getSearchedProducts } from "../services/api";
import { useNavigate } from "react-router-dom";

const Items = (props) => {
  const [loader, setLoader] = useState(false);
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchdata();
  }, []);
  const fetchdata = async () => {
    setLoader(true);
    if (props.component === "OSRS") {
      const response = await osrsProducts();
      setProducts(response.data);
    } else {
      const response = await rs3Products();
      setProducts(response.data);
    }
    setLoader(false);
  };
  const handleSearchChange=async(e)=>{
    let key ;
    if(e.target.value){
       key = e.target.value;
    }
    else{
      key = '0';
    }
   
    const response = await getSearchedProducts(key);
      setProducts(response.data);
   
   
 }
 const handleBuyNow = (product) => {
  sessionStorage.setItem("selectedAccount", JSON.stringify(product));
  sessionStorage.setItem("type", "0");
  const isAuthenticated = sessionStorage.getItem("token");
  if (!isAuthenticated) {
    sessionStorage.setItem("redirectPath", "/checkout");
    navigate("/login");
    return;
  }else{
    navigate("/checkout")
  }
};
  return (
    <div className="login-register  mt-5">
      <em class="over position-fixed bg-black opacity-85 bottom-0 top-0 end-0 start-0"></em>
      <div class="container position-relative z-1 mt-4">
        <div class="row mt-4">
          <div class="col-12 mt-4">
            <div class="row mt-4">
              <div class="col-sm-12 my-4">
                <div class="input-group mb-4 mt-2 bg-light-ben position-relative w-280">
                  <input
                    type="text"
                    class="shadow-none form-control rounded-0 bg-light-ben border-light-ben text-light-ben"
                    placeholder="Search..."
                    onChange={handleSearchChange}
                  />
                  <a href="javascript:;" class="btn pt-1 me-1 border-0">
                    <i class="fa fa-search text-light-ben mt-3"></i>
                  </a>
                </div>
              </div>
              <div class="col-6">
                <h4 class="h5 fw-bold mb-0 pb-4 text-white">
                  {props.component} Items
                </h4>
              </div>
              <div class="col-6 mb-3">
                <p class="text-light-ben float-end fs-12">{products.length} Items</p>
              </div>
              {loader ? (
                <div className="loader-card text-center my-4 h-20">
                  <div
                    className="spinner-border text-warning"
                    role="status"
                  ></div>
                </div>
              ) : (
                products.map((product) => (
                  <div class="col-lg-3 col-md-6 mb-4">
                    <div class="items-box p-3 position-relative transition">
                      <a
                        href="javascript:;"
                        class="img-holder text-center position-relative d-block text-white"
                      >
                        <span class="bg-success fs-12 py-1 px-2 d-inline-block position-absolute start-0 top-0">
                          FEATURED
                        </span>
                        <img
                          src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${product.image}`}
                          alt="Gloves"
                          class="img-fluid transition mb-3"
                          style={{ maxHeight: "200px" }}
                        />
                      </a>
                      <a
                        href="javascript:;"
                        class="text-primary-ben fw-bold fs-5"
                      >
                        {product.name}
                      </a>
                      <span class="d-block py-3 text-white">
                        {product.price}
                      </span>
                      <div class="d-flex justify-content-between align-items-center">
                        <a
                          onClick={()=>handleBuyNow(product)}                          
                          class="btn btn-primary-ben rounded-0 px-3 my-2 fs-13"
                        >
                          BUY NOW
                        </a>
                        <span class="tetx-dark opacity-50 fs-12 my-2 text-light">
                          <i class="fa fa-basket-shopping me-1"></i> 19 Sold
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Items;
