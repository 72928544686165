import React from "react";

const TermsConditions =()=>{
	return(
	<>
	<section class="dashbaord pt-md-4">
  <div class="container py-4">
    <h3 class="fs-20 fw-bold text-black text-start py-3">Terms and Conditions</h3>
    <div class="row">
      <div class="col-12">
        <strong class="d-block fs-5 my-2">1.0 bengp is a venue</strong>
        <p class="d-block lh-150 py-2">1.1 bengp acts as a venue to allow users who comply with bengp's policies to offer, sell and buy certain goods. bengp is not directly involved in the transaction between buyers and sellers. As a result, bengp has no control over the quality, safety, morality or legality of any aspect of the items listed, the truth or accuracy of the listings, the ability of sellers to sell items or the ability of buyers to pay for items. bengp does not pre-screen users (except for services that require an application) or the content or information provided by users. bengp cannot ensure that a buyer or seller will actually complete a transaction.
        <br /><br />
        Consequently, bengp does not transfer legal ownership of items from the seller to the buyer. bengp cannot guarantee the true identity, age, and nationality of a user. bengp encourages you to communicate directly with potential transaction partners through the tools available on the site.
        <br /><br />
        You agree that bengp is a venue and as such is not responsible or liable for any content, for example, data, text, information, usernames, graphics, images, photographs, profiles, audio, video, items, and links posted by you, other users, or outside parties on bengp. You use the bengp service at your own risk.</p>
        <strong class="d-block fs-5 mt-4">2.0 Eligibility</strong>
        <strong class="d-block fs-6 mt-4">2.1 Age</strong>
        <p class="d-block lh-150 py-4">bengp's services are available only to, and may only be used by, individuals who are 18 years and older who can form legally binding contracts under applicable law. You represent and warrant that you are at least 18 years old and that all registration information you submit is accurate and truthful. bengp may, in its sole discretion, refuse to offer access to or use of the Services to any person or entity and change its eligibility criteria at any time. This provision is void where prohibited by law and the right to access the Services is hereby denied in such jurisdictions.
        <br /><br />
        Individuals under the age of 18 must at all times use bengp's services only in conjunction with and under the supervision of a parent or legal guardian who is at least 18 years of age. In all cases, the adult is the user and is responsible for any and all activities.</p>
        <strong class="d-block fs-6 mt-2">2.2 Compliance</strong>
        <p class="d-block lh-150 py-4">You agree to comply with all local laws regarding online conduct and acceptable content. You are responsible for all applicable taxes. In addition, you must abide by bengp's policies as stated in the Agreement as well as all other operating rules, policies (including our Privacy Policy) and procedures that may be published from time to time on the Site by bengp, each of which is incorporated herein by reference and each of which may be updated by bengp from time to time without notice to you.
        <br /><br />
        In addition, some services offered by bengp may be subject to additional terms and conditions promulgated by bengp from time to time; your use of such services is subject to those additional terms and conditions, which are incorporated into this Agreement by this reference.
        <br /><br />
        By using bengp's services you represent and warrant that you are acting in full compliance with the terms and conditions of the relevant third-party company for any product you purchase or sell on the venue.</p>
        <strong class="d-block fs-6 mt-2">2.3 Password</strong>
        <p class="d-block lh-150 py-4">Keep your password secure. You are fully responsible for all activity, liability and damage resulting from your failure to maintain password confidentiality. You agree to immediately notify bengp of any unauthorized use of your password or any breach of security. You also agree that bengp cannot and will not be liable for any loss or damage arising from your failure to keep your password secure. You agree not to provide your username and password information in combination to any other party other than bengp without bengp's express written permission.</p>
        <strong class="d-block fs-6 mt-2">2.4 Account Information</strong>
        <p class="d-block lh-150 py-4">You must keep your account information up-to-date and accurate at all times, including a valid email address. To sell items on bengp you must provide and maintain valid payment information such as valid credit card information.</p>
        <strong class="d-block fs-6 mt-2">2.5 Account Transfer</strong>
        <p class="d-block lh-150 py-4">You may not transfer or sell your bengp account and User ID to another party. You are responsible for all activity of the account and User ID. If you are registering as a business entity, you personally guarantee that you have the authority to bind the entity to this Agreement.</p>
        <strong class="d-block fs-6 mt-2">2.6 Right to Refuse Service</strong>
        <p class="d-block lh-150 py-4">bengp's services are not available to temporarily or indefinitely suspended bengp members. bengp reserves the right, in bengp's sole discretion, to cancel unconfirmed or inactive accounts. bengp reserves the right to refuse service to anyone, for any reason, at any time.</p>
        <strong class="d-block fs-5 mt-2">3.0 Fees</strong>
        <p class="d-block lh-150 py-4">Joining bengp is free. bengp charges fees for items sold via the Services. When you list an item, you have an opportunity to review and accept the fees that you will be charged.
        <br /><br />
        You are responsible for paying all fees and applicable taxes associated with using bengp.
        <br /><br />
        If bengp terminates a listing or your account, if you close your account, or if the payment of your bengp fees cannot be completed for any reason, you remain obligated to pay bengp for all unpaid fees plus any penalties, if applicable. If the seller's account is not paid in full and becomes past due, the seller risks penalties such as the suspension of privileges and/or termination of the account and other collection mechanisms (including retaining collection agencies and legal counsel).</p>
        <strong class="d-block fs-5 mt-2">4.0 Listing, Selling, and Buying</strong>
        <strong class="d-block fs-6 mt-4">4.1 Listing Description</strong>
        <p class="d-block lh-150 py-4">All listings on bengp must be for sale. By listing an item, you warrant that you and all aspects of the item comply with bengp's policies. You also warrant that you may legally sell the item. Your listings may only include text descriptions, graphics, pictures and other content relevant to the sale of that item. All items must be listed in an appropriate category with appropriate tags. Each listing must accurately and completely describe the item/items for sale in that listing.</p>
        <strong class="d-block fs-6 mt-2">4.2 Binding Sale</strong>
        <p class="d-block lh-150 py-4">Sellers are responsible for accurately listing their items, and buyers are responsible for reading the description of items before making a purchase. All sales are binding. The seller is obligated to ship the order or otherwise complete the transaction with the buyer in a prompt manner, unless there is an exceptional circumstance, such as: (a) the buyer fails to meet the terms of the seller's listing (such as payment method), or (b) the seller cannot authenticate the buyer's identity. The buyer is obligated to deliver appropriate payment for items purchased, unless there is an exceptional circumstance.</p>
        <strong class="d-block fs-6 mt-2">4.3 Fee Avoidance</strong>
        <p class="d-block lh-150 py-4">The price stated in each item listing description must be an accurate representation of the sale. Sellers may charge reasonable shipping and handling fees to cover the costs for packaging and mailing the items. Sellers may not charge excessive shipping fees or otherwise avoid fees. You may not alter the item's price after a sale for the purpose of avoiding bengp transaction fees, misrepresent the item's location, or use another user's account without permission.</p>
        <strong class="d-block fs-6 mt-2">4.4 Payment Processing</strong>
        <p class="d-block lh-150 py-4">bengp, in its sole discretion, may inspect and verify (i) details related to the listing sold, (ii) the buyer of the listing and (iii) the seller of the listing. As a part of the verification process, bengp may require the seller or buyer to provide additional identification information, including, without limitation, a copy of a valid driver's license (or other form of government issued identification) prior to or during the transaction. Users may also be asked to go through additional security procedures and share additional information to confirm their identity. bengp reserves the right to reject any item(s) that we believe (in our sole discretion) may: (i) be fraudulent, invalid, inauthentic or stolen, (ii) have come from an unauthorized or illegal source, (iii) be related to any illegal activity or (iv) otherwise pose a financial risk to us or our users.
        <br /><br />
        You acknowledge that payment processing services are provided by Payment Processors, including PayOp and CardPay and are subject to the their EULAs. By using bengp, you agree to be bound by the terms in this section and the Payment Services Agreement, as the same may be modified by us or by the Payment Processors from time to time. Capitalized terms in this section otherwise undefined in this Agreement shall have the meaning ascribed to such terms as in the Payment Services Agreements. As a condition of selling, you agree to provide bengp accurate and complete information about you and your business, and you authorize bengp to share, in accordance with the Payment Services Agreement, such information and applicable transaction information related to your use of the payment processing services provided by the Payment Processors.</p>
        <strong class="d-block fs-5 mt-2">5.0 Prohibited, Questionable and Infringing Items and Activities</strong>
        <strong class="d-block fs-6 mt-4">5.1 User Responsibility</strong>
        <p class="d-block lh-150 py-4">You are solely responsible for your conduct and activities on and regarding to bengp and any and all data, text, information, usernames, graphics, images, photographs, profiles, audio, video, items, and links (together, "Content") that you submit, post, and display on bengp.</p>
        <strong class="d-block fs-6 mt-2">5.2 Restricted Activities</strong>
        <p class="d-block lh-150 py-4">Your Content and your use of bengp shall not:</p>
        <ul>
          <li type="disc" class="ms-3 py-1"><p class="d-block lh-150 py-1">Be false, inaccurate or misleading</p></li>
          <li type="disc" class="ms-3 py-1"><p class="d-block lh-150 py-1">Be fraudulent or involve the sale of illegal, counterfeit or stolen items</p></li>
          <li type="disc" class="ms-3 py-1"><p class="d-block lh-150 py-1">Infringe upon any third-party's copyright, patent, trademark, trade secret or other proprietary or intellectual property rights or rights of publicity or privacy</p></li>
          <li type="disc" class="ms-3 py-1"><p class="d-block lh-150 py-1">Violate this Agreement, any policy or community guidelines, or any applicable law, statute, ordinance or regulation (including, but not limited to, those governing export control, consumer protection, unfair competition, anti-discrimination or false advertising)</p></li>
          <li type="disc" class="ms-3 py-1"><p class="d-block lh-150 py-1">Violate the terms and conditions or any contractual agreement you have made with any third-party companies.</p></li>
          <li type="disc" class="ms-3 py-1"><p class="d-block lh-150 py-1">Involve any countries, entities, individuals or items prohibited by sanctions, embargoes, regulations or orders administered by the U.S. Department of Treasury’s Office of Foreign Assets Control (“OFAC”) or other government agencies</p></li>
          <li type="disc" class="ms-3 py-1"><p class="d-block lh-150 py-1">Contain items that have been identified by the U.S. Consumer Products Safety Commission (CPSC) as hazardous to consumers and therefore subject to a recall</p></li>
          <li type="disc" class="ms-3 py-1"><p class="d-block lh-150 py-1">Be defamatory, trade libelous, unlawfully threatening, unlawfully harassing, impersonate or intimidate any person (including bengp staff or other users), or falsely state or otherwise misrepresent your affiliation with any person, through for example, the use of similar email address, nicknames, or creation of false account(s) or any other method or device</p></li>
          <li type="disc" class="ms-3 py-1"><p class="d-block lh-150 py-1">Interfere with a seller’s business or shop</p></li>
          <li type="disc" class="ms-3 py-1"><p class="d-block lh-150 py-1">Take any action that may undermine online reviews or feedback</p></li>
          <li type="disc" class="ms-3 py-1"><p class="d-block lh-150 py-1">Be obscene or contain child pornography</p></li>
          <li type="disc" class="ms-3 py-1"><p class="d-block lh-150 py-1">Contain or transmit any code of a destructive nature that may damage, detrimentally interfere with, surreptitiously intercept or expropriate any system, data or personal information</p></li>
          <li type="disc" class="ms-3 py-1"><p class="d-block lh-150 py-1">Host images not part of a listing</p></li>
          <li type="disc" class="ms-3 py-1"><p class="d-block lh-150 py-1">Modify, adapt or hack bengp or modify another web site so as to falsely imply that it is associated with bengp</p></li>
          <li type="disc" class="ms-3 py-1"><p class="d-block lh-150 py-1">Appear to create liability for bengp or cause bengp to lose (in whole or in part) the services of bengp's ISPs or other suppliers</p></li>
          <li type="disc" class="ms-3 py-1"><p class="d-block lh-150 py-1">Link directly or indirectly, reference or contain descriptions of goods or services that are prohibited under this Agreement or other policy documents as posted on bengp.</p></li>
          <li type="disc" class="ms-3 py-1"><p class="d-block lh-150 py-1">Furthermore, you may not list any item on bengp (or consummate any transaction that was initiated using bengp's service that could cause bengp to violate any applicable law, statute, ordinance or regulation, or that violates the Terms of Use.</p></li>
        </ul>
        <strong class="d-block fs-5 mt-4">6.0 Copyright Policy</strong>
        <p class="d-block lh-150 py-4">As stated above, your Content and your use of bengp shall not infringe upon any third-party's intellectual property rights. bengp respects the intellectual property of others and asks that users of our Services do the same. We have adopted and implemented a policy respecting copyright law that provides for the removal of any infringing materials and for the termination, in appropriate circumstances, of users of our Services who are repeat infringers of intellectual property rights, including copyrights. If you believe that one of our users is, through the use of our Services, unlawfully infringing the copyright(s) in a work, and wish to have the allegedly infringing material removed, the following information in the form of a written notification (pursuant to 17 U.S.C. § 512(c)) must be provided to our designated Copyright Agent:</p>
        <ul>
          <li type="disc" class="ms-3 py-1"><p class="d-block lh-150 py-1">Your physical or electronic signature;</p></li>
          <li type="disc" class="ms-3 py-1"><p class="d-block lh-150 py-1">Identification of the copyrighted work(s) that you claim to have been infringed;</p></li>
          <li type="disc" class="ms-3 py-1"><p class="d-block lh-150 py-1">Identification of the material on our Services that you claim is infringing and that you request us to remove;</p></li>
          <li type="disc" class="ms-3 py-1"><p class="d-block lh-150 py-1">Sufficient information to permit us to locate such material;</p></li>
          <li type="disc" class="ms-3 py-1"><p class="d-block lh-150 py-1">Your address, telephone number, and e-mail address;</p></li>
          <li type="disc" class="ms-3 py-1"><p class="d-block lh-150 py-1">A statement that you have a good faith belief that use of the objectionable material is not authorized by the copyright owner, its agent, or under the law; and</p></li>
          <li type="disc" class="ms-3 py-1"><p class="d-block lh-150 py-1">A statement that the information in the notification is accurate, and under penalty of perjury, that you are either the owner of the copyright that has allegedly been infringed or that you are authorized to act on behalf of the copyright owner.</p></li>
        </ul>
        <p class="d-block lh-150 py-4">Please note that, pursuant to 17 U.S.C. § 512(f), any misrepresentation of material fact (falsities) in a written notification automatically subjects the complaining party to liability for any damages, costs and attorney’s fees incurred by us in connection with the written notification and allegation of copyright infringement.
        <br /><br />
        The contact information for the Copyright Agent for bengp is as follows:
        <br /><br />
        Email: <a href="mailto:support@bengp.com" class="text-primary-ben">support@bengp.com</a></p>
        <strong class="d-block fs-5 mt-2">7.0 Content</strong>
        <strong class="d-block fs-6 mt-4">7.1 License</strong>
        <p class="d-block lh-150 py-4">You grant bengp a license solely to enable bengp to use any information or Content you supply bengp with, so that bengp is not violating any rights you might have in that Content. You grant bengp a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sublicensable (through multiple tiers) right to exercise the copyright, publicity, and database rights (but no other rights) you have in the Content, in any media now known or not currently known, with respect to your Content. You agree to allow bengp to store, translate, or re-format your Content on bengp and display your Content on bengp in any way bengp chooses. bengp will only use personal information in accordance with bengp's <a href="javascript:;" class="text-primary-ben">Privacy Policy</a>.
        <br /><br />
        As part of a transaction, you may obtain personal information, including email address and shipping information, from another bengp user. Without obtaining prior permission from the other user, this personal information shall only be used for that transaction or for bengp-related communications. bengp has not granted you a license to use the information for unsolicited commercial messages or unauthorized transactions. Without limiting the foregoing, without express consent from the user, you are not licensed to add any bengp user to your email or physical mail list. For more information, see bengp's <a href="javascript:;" class="text-primary-ben">Privacy Policy</a>.</p>
        <strong class="d-block fs-6 mt-2">7.2 Re-Posting Content</strong>
        <p class="d-block lh-150 py-4">By posting Content on bengp, it is possible for an outside web site or a third party to re-post that Content. You agree to hold bengp harmless for any dispute concerning this use. If you choose to display your own bengp-hosted image on another web site, the image must provide a link back to its listing page on bengp.
        <br /><br />
        Idea Submissions
        <br /><br />
        bengp considers any unsolicited suggestions, ideas, proposals or other material submitted to it by users via the Service, mobile applications, or otherwise (other than the Content and the tangible items sold via the Service by users) (collectively, the "Material") to be non-confidential and non-proprietary, and bengp shall not be liable for the disclosure or use of such Material. If, at bengp's request, any member sends Material to improve the Service (for example through the Forums or to customer support), bengp will also consider that Material to be non-confidential and non-proprietary and bengp will not be liable for use or disclosure of the Material. Any communication by you to bengp is subject to this Agreement. You hereby grant and agree to grant bengp, under all of your rights in the Material, a worldwide, non-exclusive, perpetual, irrevocable, royalty-free, fully-paid, sublicensable and transferable right and license to incorporate, use, publish and exploit such Material for any purpose whatsoever, commercial or otherwise, including but not limited to incorporating it in the API, documentation, or any product or service, without compensation or accounting to you and without further recourse by you.</p>
        <strong class="d-block fs-5 mt-2">8.0 Information Control</strong>
        <p class="d-block lh-150 py-4">bengp does not control the Content provided by users that is made available on bengp. You may find some Content to be offensive, harmful, inaccurate, or deceptive. There are also risks of dealing with underage persons or people acting under false pretense. Additionally, there may also be risks dealing with international trade and foreign nationals. By using bengp, you agree to accept such risks and that bengp (and bengp's officers, directors, agents, subsidiaries, joint ventures and employees) is not responsible for any and all acts or omissions of users on bengp. Please use caution, common sense, and practice safe buying and selling when using bengp.</p>
        <strong class="d-block fs-5 mt-2">9.0 Resources</strong>
        <p class="d-block lh-150 py-4">bengp is not responsible for the availability of outside web sites or resources linked to or referenced on the Service. bengp does not endorse and is not responsible or liable for any content, advertising, products, or other materials on or available from such web sites or resources. You agree that bengp shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or resources.</p>
        <strong class="d-block fs-5 mt-2">10.0 Resolution of Disputes and Release</strong>
        <strong class="d-block fs-6 mt-4">10.1 Disputes with bengp</strong>
        <p class="d-block lh-150 py-4">In the event a dispute arises between you and bengp, please contact bengp. Any dispute arising from or relating to the subject matter of this Agreement shall be finally settled by arbitration in Cyprus., using the English language in accordance with the Arbitration Rules and Procedures of Judicial Arbitration and Mediation Services, Inc. ("JAMS") then in effect, by one commercial arbitrator with substantial experience in resolving intellectual property and commercial contract disputes, who shall be selected from the appropriate list of JAMS arbitrators in accordance with the Arbitration Rules and Procedures of JAMS. The prevailing party in any arbitration or other proceeding arising under this Agreement shall be entitled to receive reimbursement of its reasonable expenses (including reasonable attorneys' fees, expert witness fees and all other expenses) incurred in connection therewith. Judgment upon the award so rendered may be entered in a court having jurisdiction or application may be made to such court for judicial acceptance of any award and an order of enforcement, as the case may be. Notwithstanding the foregoing, each party shall have the right to institute an action in a court of proper jurisdiction for injunctive or other equitable relief pending a final decision by the arbitrator. For all purposes of this Agreement, the parties consent to exclusive jurisdiction and venue in Cyprus Courts. Use of the Services is not authorized in any jurisdiction that does not give effect to all provisions of the Agreement, including without limitation, this section. You and bengp agree that any cause of action arising out of or related to the Services (including, but not limited to, any services provided or made available therein) or this Agreement must commence within one (1) year after the cause of action arose; otherwise, such cause of action is permanently barred.</p>
        <strong class="d-block fs-6 mt-2">10.2 Disputes with users or third parties</strong>
        <p class="d-block lh-150 py-4">In the event a dispute arises between you and another user or a third party, bengp encourages you to contact the user or third party to resolve the dispute amicably.
        <br /><br />
        If a buyer and seller are unable to resolve a dispute resulting from a transaction that occurs on bengp, please contact bengp, and we can provides our dispute resolution process for the benefit of users. bengp does so in bengp's sole discretion, and bengp has no obligation to resolve disputes between users or between users and outside parties. To the extent that bengp attempts to resolve a dispute, bengp will do so in good faith based solely on bengp's policies. bengp will not make judgments regarding legal issues or claims.
        <br /><br />
        You may also report user-to-user disputes to your local law enforcement, postmaster general, or a certified mediation or arbitration entity, as applicable.
        <br /><br />
        You release bengp (and bengp's officers, directors, agents, subsidiaries, joint ventures and employees) from any and all claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with disputes with one or more users, or an outside party.</p>
        <strong class="d-block fs-5 mt-2">11.0 Breach</strong>
        <p class="d-block lh-150 py-4">Without limiting any other remedies, bengp may, without notice, and without refunding any fees, delay or immediately remove Content, warn bengp's community of a user's actions, issue a warning to a user, temporarily suspend a user, temporarily or indefinitely suspend a user's account privileges, terminate a user's account, prohibit access to the Services, and take technical and legal steps to keep a user off the Services and refuse to provide services to a user if any of the following apply:
        <br /><br />
        bengp suspects (by information, investigation, conviction, settlement, insurance or escrow investigation, or otherwise) a user has breached this Agreement or other policy documents and community guidelines incorporated herein; bengp is unable to verify or authenticate any of your personal information or Content; or bengp believes that a user is acting inconsistently with the letter or spirit of bengp's policies, has engaged in improper or fraudulent activity in connection with bengp or the actions may cause legal liability or financial loss to bengp's users or to bengp.
        <br /><br />
        bengp reserves the right to suspend and/or terminate a person's account or any accounts held by that person by virtue of association, including all usernames under which that person operates on bengp.</p>
        <strong class="d-block fs-5 mt-2">12.0 No Warranty</strong>
        <p class="d-block lh-150 py-4">bengp, bengp'S SUBSIDIARIES, OFFICERS, DIRECTORS, EMPLOYEES, AND bengp'S SUPPLIERS PROVIDE bengp'S SERVICES "AS IS" AND WITHOUT ANY WARRANTY OR CONDITION, EXPRESS, IMPLIED OR STATUTORY. bengp, bengp'S SUBSIDIARIES, OFFICERS, DIRECTORS, EMPLOYEES AND bengp'S SUPPLIERS SPECIFICALLY DISCLAIM ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, PERFORMANCE, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. IN ADDITION, NO ADVICE OR INFORMATION (ORAL OR WRITTEN) OBTAINED BY YOU FROM bengp SHALL CREATE ANY WARRANTY. SOME STATES DO NOT ALLOW THE DISCLAIMER OF IMPLIED WARRANTIES, SO THE FOREGOING DISCLAIMER MAY NOT APPLY TO YOU. THIS WARRANTY GIVES YOU SPECIFIC LEGAL RIGHTS AND YOU MAY ALSO HAVE OTHER LEGAL RIGHTS THAT VARY FROM STATE TO STATE.</p>
        <strong class="d-block fs-6 mt-2">13.0 Liability Limit</strong>
        <p class="d-block lh-150 py-4">IN NO EVENT SHALL bengp, AND (AS APPLICABLE) bengp'S SUBSIDIARIES, OFFICERS, DIRECTORS, EMPLOYEES OR bengp'S SUPPLIERS BE LIABLE FOR ANY DAMAGES WHATSOEVER, WHETHER DIRECT, INDIRECT, GENERAL, SPECIAL, COMPENSATORY, CONSEQUENTIAL, AND/OR INCIDENTAL, ARISING OUT OF OR RELATING TO THE CONDUCT OF YOU OR ANYONE ELSE IN CONNECTION WITH THE USE OF THE SERVICES, OR THIS AGREEMENT, INCLUDING WITHOUT LIMITATION, LOST PROFITS, BODILY INJURY, EMOTIONAL DISTRESS, OR ANY SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES.
        <br /><br />
        bengp'S LIABILITY, AND (AS APPLICABLE) THE LIABILITY OF bengp'S SUBSIDIARIES, OFFICERS, DIRECTORS, EMPLOYEES, AND SUPPLIERS, TO YOU OR ANY THIRD PARTIES IN ANY CIRCUMSTANCE IS LIMITED TO THE GREATER OF (A) THE AMOUNT OF FEES YOU PAY TO bengp IN THE 12 MONTHS PRIOR TO THE ACTION GIVING RISE TO LIABILITY, AND (B) $100. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.</p>
        <strong class="d-block fs-5 mt-2">14.0 Indemnity</strong>
        <p class="d-block lh-150 py-4">YOU AGREE TO INDEMNIFY AND HOLD bengp AND (AS APPLICABLE) bengp'S PARENT, SUBSIDIARIES, AFFILIATES, OFFICERS, DIRECTORS, AGENTS, AND EMPLOYEES, HARMLESS FROM ANY CLAIM OR DEMAND, INCLUDING REASONABLE ATTORNEYS' FEES, MADE BY ANY THIRD PARTY DUE TO OR ARISING OUT OF YOUR BREACH OF THIS AGREEMENT OR THE DOCUMENTS IT INCORPORATES BY REFERENCE, OR YOUR VIOLATION OF ANY LAW OR THE RIGHTS OF A THIRD PARTY.</p>
        <strong class="d-block fs-5 mt-2">15.0 No Guarantee</strong>
        <p class="d-block lh-150 py-4">bengp does not guarantee continuous, uninterrupted access to the Service, and operation of the Service may be interfered with by numerous factors outside bengp's control.</p>
        <strong class="d-block fs-5 mt-2">16.0 Legal Compliance; Taxes</strong>
        <p class="d-block lh-150 py-4">You shall comply with all applicable domestic and international laws, statutes, ordinances and regulations regarding your use of the Services and, if applicable, your listing, purchase, solicitation of offers to purchase, and sale of items. In addition, you shall be responsible for paying any and all taxes applicable to any purchases or sales of items you make using the Services (excluding any taxes on bengp's net income).</p>
        <strong class="d-block fs-5 mt-2">17.0 Severability</strong>
        <p class="d-block lh-150 py-4">If any provision of this Agreement is held unenforceable, then such provision will be modified to reflect the parties' intention. All remaining provisions of this Agreement shall remain in full force and effect.</p>
        <strong class="d-block fs-5 mt-2">18.0 No Agency</strong>
        <p class="d-block lh-150 py-4">You and bengp are independent contractors, and no agency, partnership, joint venture, employee-employer or franchiser-franchisee relationship is intended or created by this Agreement.</p>
        <strong class="d-block fs-5 mt-2">19.0 bengp Service</strong>
        <p class="d-block lh-150 py-4">bengp reserves the right to modify or terminate the bengp service for any reason, without notice, at any time. bengp reserves the right to alter these Terms of Use or other policies at any time, so please review the policies frequently.</p>
        <strong class="d-block fs-5 mt-2">20.0 Exchange</strong>
        <p class="d-block lh-150 py-4">bengp is not in any way shape or form a digital currency exchange service. Attempting to use our website in such ways is strictly forbidden and will result in a permanent ban from the website.</p>
        <strong class="d-block fs-5 mt-2">21.0 Escrow</strong>
        <p class="d-block lh-150 py-4">bengp is not in any way shape or form an escrow service. Attempting to use our website in such ways is strictly forbidden and will result in a permanent ban from the website. By using our website you agree that the items you are buying or selling are for you and not a third party.</p>
        <strong class="d-block fs-5 mt-2">22.0 Changes to Agreement</strong>
        <p class="d-block lh-150 py-4">bengp reserves the right to modify the terms and agreements at any time. It is your responsibility to check for any changes listed in the Terms of Service. Your decision to continue use of bengp.com constitutes formal acceptance of the new Terms of Service. Should you not agree to any provision to the Terms of Service, we hereby ask and advise you to immediately terminate use of any service provided by bengp.</p>
        <strong class="d-block fs-5 mt-2">23.0 Acceptance of Terms</strong>
        <p class="d-block lh-150 py-4">Use of any services provided by bengp.com constitutes your acceptance of the Terms of Service agreement.</p>
        <strong class="d-block fs-5 mt-2">24.0 Company Information</strong>
        <p class="d-block lh-150 py-4">Statpoint Support Services Limited<br />
        HE 410270<br />
        Avlonos, 1 Maria House,<br />
        1075, Nicosia, Cyprus.<br /><br />
        As bengp operates in close association with Statpoint Support Services Limited (“SSSL”), please refer any questions related to bengp and these Terms of Service to SSSL at its address as set forth above.</p>
        <strong class="d-block fs-5 mt-2">25.0 Governing Law</strong>
        <p class="d-block lh-150 py-4">These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of the Cyprus.</p>
      </div>
    </div>
  </div>
</section>
	</>);
}
export default TermsConditions;