import React from "react";
import { useState } from "react";
import Head from "./layouts/Head";
import Foot from "./layouts/Foot";

const Layout = ({ children, component }) => {
  const [showChat, setShowChat] = useState(false);
  const [firstmessage, setFirstMessage] = useState(null);

  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      firstmessage,
      setFirstMessage,
      showChat,
      setShowChat,
    })
  );
  return (
    <>
      <Head component={component} />
      {childrenWithProps}
      <Foot
        showChat={showChat}
        setShowChat={setShowChat}
        firstmessage={firstmessage}
        setFirstMessage={setFirstMessage}
      />
    </>
  );
};

export default Layout;
