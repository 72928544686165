import React from "react";
import { useState, useEffect} from "react";
import Chat from "../widgets/Chat";
import { Link } from "react-router-dom";

import logo from '../../website-assets/images/logo-footer.png';

const Foot=(props)=>{

	return (
		<>
		<footer class="bg-dark-ben pt-4 position-relative z-1 mt-5">
		<div class="container pb-4">
			<div class="row text-white">
			<div class="col-md-3 mt-4">
				<div class="pe-md-5">
				<Link to="/" class="footer-logo d-block pe-4"><img src={logo} alt="Footer Logo" class="img-fluid rounded-3" /></Link>
				</div>
			</div>
			<div class="col-md-3 col-sm-4 mt-4">
				<h4 class="h5 fw-bold">Contact Us</h4>
				<ul class="d-block mt-3">
				<li class="d-block py-2"><a href="mailto:info@bengp.com">info@bengp.com</a></li>
				<li class="d-block py-2"><a href="https://discord.com/"><img width="40" height="40" src="https://img.icons8.com/3d-fluency/94/discord-logo.png" target="_blank" alt="discord-logo"/></a></li>
				</ul>
			</div>
			<div class="col-md-3 col-sm-4 mt-4">
				<h4 class="h5 fw-bold">Featured</h4>
				<ul class="d-block mt-3">
				<li class="d-block py-2"><Link to="/">OSRS Gold</Link></li>
				<li class="d-block py-2"><Link to="/rs3-gold">RS3 Gold</Link></li>
				<li class="d-block py-2"><Link to="/osrs-accounts">OSRS Accounts</Link></li>
				<li class="d-block py-2"><Link to="/rs3-accounts">RS3 Accounts</Link></li>
				<li class="d-block py-2"><Link to="/osrs-items">OSRS Items</Link></li>
				<li class="d-block py-2"><Link to="/rs3-items">RS3 Items</Link></li>
				</ul>
			</div>
			<div class="col-md-3 col-sm-4 mt-4">
				<h4 class="h5 fw-bold">Privacy Policy</h4>
				<ul class="d-block mt-3">
				<li class="d-block py-2"><Link to="/privacy-policy">Privacy Policy</Link></li>
				<li class="d-block py-2"><Link to="/GDPR-policy">GDPR Privacy Notice</Link></li>
				<li class="d-block py-2"><Link to="/terms-condition">Terms of Service</Link></li>
				<li class="d-block py-2"><Link to="/refund-policy">Refund Policy</Link></li>
				</ul>
			</div>
			</div>
		</div>
		<div class="copyright text-center p-4 mt-5 text-white">
			<p class="fs-12">Copyright &copy; 2024 BenGP. All Rights Reserved.</p>
		</div>
		</footer>
		<Chat showChat = {props.showChat} setShowChat = {props.setShowChat} firstmessage={props.firstmessage} setFirstMessage={props.setFirstMessage}/>
		
		</>
	);
}

export default Foot;