import React from "react";

const ImageModal = ({ imageUrl, altText, isOpen, onClose }) => {
  if (!isOpen) return null; // Return null if the modal is not open

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content">
        <img src={imageUrl} alt={altText} className="large-image" />
      </div>
    </div>
  );
};

export default ImageModal;