import React from "react";
import {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import { Link } from "react-router-dom";
import Logo from '../../website-assets/images/logo.svg'
import solider from '../../website-assets/images/soldier.png'
import visa from "../../website-assets/images/visa.svg";
import binance from "../../website-assets/images/binance.svg";
import bitcoin from "../../website-assets/images/bitcoin.svg";
import { coinTransaction } from "../services/api";
import { getCheckoutUrl } from "../services/api";


const Checkout=()=>{
	const [element,setElement] = useState({});
	const [price, setPrice] = useState(0);
	const [from_currency,setFormCurrency]= useState('USD');
	const [category, setCategory] = useState(0);
	const [runescapename, setRunescapename] = useState();
	const [runescapeemail, setRunescapeemail] = useState();
	const [selectedPayment, setSelectedPayment] = useState('');
	const [quantity, setQuantity] = useState(0);
	const [type, setType] =  useState();
	const [isloading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	useEffect(() => {
		setType(sessionStorage.getItem('type'));
		setSelectedPayment(sessionStorage.getItem('paymentMethod'));
		const sessiontype =sessionStorage.getItem('type')

			if(!sessionStorage.getItem("selectedAccount")){
			navigate("/");
			}
		else{
			
			setElement(JSON.parse(sessionStorage.getItem('selectedAccount')));
			if(sessiontype === "1"){
				setPrice(JSON.parse(sessionStorage.getItem('selectedAccount')).cost);
	
			}else if (sessiontype === '0'){

				 setPrice(JSON.parse(sessionStorage.getItem('selectedAccount')).minimum_buy_limit * JSON.parse(sessionStorage.getItem('selectedAccount')).price);
				 setQuantity(JSON.parse(sessionStorage.getItem('selectedAccount')).minimum_buy_limit);
			}else {
				setCategory(JSON.parse(sessionStorage.getItem('selectedAccount')).category);
				setPrice(JSON.parse(sessionStorage.getItem('selectedAccount')).usdbuyValue);
				setQuantity(JSON.parse(sessionStorage.getItem('selectedAccount')).mbuyValue);
				setRunescapename(JSON.parse(sessionStorage.getItem("selectedAccount")).runescapename);
				setRunescapeemail(sessionStorage.getItem("user_email"));
			}
		}
	},[]);


	const handlePaymentChange = (event) => {
		setSelectedPayment(event.target.value);
		sessionStorage.setItem("paymentMethod",event.target.value);
	};

	  const handleChangerunescapename=(e)=>{
			setRunescapename(e.target.value);
	  }
	  const handleChangeEmail=(e)=>{
		setRunescapeemail(e.target.value)
	  }
	  
	  const [formData, setFormData] = useState({
		name: '',
		email: '',
	  });
		const [errors, setErrors] = useState({});
		const handleChange = (e) => {
			const { name, value, type, checked } = e.target;
			setFormData({
			  ...formData,
			  [name]: type === 'checkbox' ? checked : value
			});
		  };
		  const validate = () => {
			let errors = {};
			if(type == '2')
			{
				return true;
			}
			if (!formData.email) {
			  errors.email = 'Email is required';
			} else if (!/\S+@\S+\.\S+/.test(formData.email)) {
			  errors.email = 'Email address is invalid';
			}
			
			setErrors(errors);
		
			return Object.keys(errors).length === 0;
		  };
		  const handleSubmit = async (e) => {
			e.preventDefault();
			if (validate()) {
				const token = sessionStorage.getItem('token');
				const id = element.id;
				const userid = sessionStorage.getItem("user_id");
				const updatedFormData = {
					...formData,
					runescapeemail,
					runescapename,
					price,
					id,
					quantity,
					type,
					userid,
					category,
				  };
				setIsLoading(true);
				if(selectedPayment === "bitcoin")
				{
					const response =await coinTransaction(token,updatedFormData);
					sessionStorage.removeItem("selectedAccount");
					sessionStorage.removeItem("type");
					if(response){
						console.log(response);
						sessionStorage.setItem("orderGenerated", JSON.stringify(response.data.order));
						setIsLoading(false);
						window.location.href = response.data.result.checkout_url;	
					}			
				}else if(selectedPayment === "visa")
				{
					const response = await getCheckoutUrl(token, updatedFormData);
					sessionStorage.removeItem("selectedAccount");
					sessionStorage.removeItem("type");
					if(response){
						console.log(response);
						sessionStorage.setItem("orderGenerated",  JSON.stringify(response.data.order));
						setIsLoading(false);
						window.location.href = response.data.checkout.redirect;	
					}
				}

				
			}
		  };

		  const incrementQuantity =(e)=>{
			e.preventDefault();
				setQuantity(parseInt(quantity)+1);
				setPrice(price+parseInt(JSON.parse(sessionStorage.getItem('selectedAccount')).price));
		  }
		  const decrementQuantity=(e)=>{
			e.preventDefault();
			const buylimit = JSON.parse(sessionStorage.getItem('selectedAccount')).minimum_buy_limit;
			if(quantity > buylimit){
				setQuantity(quantity-1);
				setPrice(price-JSON.parse(sessionStorage.getItem('selectedAccount')).price);
			}
		  }

	return (
		<>
	<div className="login-register">
					<em class="over position-absolute bg-black opacity-75 bottom-0 top-0 end-0 start-0"></em>
					<div class="container vh-100 position-relative z-1">
					<div class="row align-items-center h-100">
						<div class="col-lg-2 col-md-1"></div>
						<div class="col-lg-8 col-md-10">
						<section class="bg-dark-ben rounded-3 overflow-hidden shadow p-4 my-4">
							<div class="row">
							<div class="col-md-12">
								{isloading?(<>								
								<div className="text-center order-loading-div ">
									<div className="spinner-border text-warning" role="status"></div>
									<br></br>
									<div className="loader-text mt-4">
									<span className="text-warning mt-4">Processing Order</span>
									</div>
								</div>
								</>):(<>
									<div class="text-center my-4"><Link to="/"><img src={Logo} alt="Logo" class="img-fluid" width="130" /></Link><br /><h1 class="h3 fw-bold text-white py-4">Finalize Your Order</h1></div>
									<form onSubmit={handleSubmit}>
									{errors.apiError && <span className="text-danger">{errors.apiError}</span>}
									{sessionStorage.getItem("type") === '2'?(
										<>
											{errors.name && <span className="text-danger">{errors.name}</span>}
												<div class="input-group mb-4 mt-2 bg-light-ben">
													<input type="text" class="shadow-none form-control rounded-0 bg-light-ben border-light-ben text-light-ben" name="runescapename" placeholder="Enter Your Name*" value={runescapename} onChange={handleChangerunescapename} />
												</div>
												{errors.email && <span className="text-danger">{errors.email}</span>}
												<div class="input-group mb-4 mt-2 bg-light-ben">
													<input type="text" class="shadow-none form-control rounded-0 bg-light-ben border-light-ben text-light-ben" name="email" placeholder="Enter Your Email*" value={runescapeemail} onChange={handleChangeEmail} />
												</div>
										</>):(<>
											{errors.name && <span className="text-danger">{errors.name}</span>}
										<div class="input-group mb-4 mt-2 bg-light-ben">
											<input type="text" class="shadow-none form-control rounded-0 bg-light-ben border-light-ben text-light-ben" name="name" placeholder="Enter Your Name*" value={formData.name} onChange={handleChange} />
										</div>
										{errors.email && <span className="text-danger">{errors.email}</span>}
										<div class="input-group mb-4 mt-2 bg-light-ben">
											<input type="text" class="shadow-none form-control rounded-0 bg-light-ben border-light-ben text-light-ben" name="email" placeholder="Enter Your Email*" value={formData.email} onChange={handleChange} />

										</div></>)}
									
									
									{element && type == '0' && price && quantity?(
										<div className="invoice-products">
										<span class="heading">Order Summary</span>
										<table className="mt-2">
											<tr>
												<td>Price:</td>
												<td>${price?JSON.parse(sessionStorage.getItem('selectedAccount')).price:<>0</>}</td>
											</tr>
											<tr>
												<td>Quantity:</td>
												<td><div class="input-group mb-4 mt-2 bg-light-ben">
												<button class="btn btn-primary-ben rounded-0 fw-bold p-3 text-uppercase" onClick={incrementQuantity}><i class="fa fa-plus"></i></button><input type="text" class="ml-2 w-40 shadow-none form-control rounded-0 bg-light-ben border-light-ben text-light-ben" value={quantity}	 placeholder="Quantity"  readOnly/><button class="btn btn-primary-ben rounded-0 fw-bold p-3 text-uppercase" onClick={decrementQuantity}><i class="fa fa-minus"></i></button>		
											</div></td>
											</tr>
											<tr>
												<td>Sub Total:</td>
												<td>$ {price?JSON.parse(sessionStorage.getItem('selectedAccount')).price:<>0</>} * {quantity}</td>
											</tr>
											<tr>
												<td>Total:</td>
												<td>${price}</td>
											</tr>
											</table>
										</div>):element && type == '1'?(
											<>
												<div className="invoice-products">
												<span class="heading">Order Summary</span>
												<table className="mt-2">
													<tr>
														<td>Total:</td>
														<td>${price}</td>
													</tr>
												</table>
												</div>
											</>):element && type == '2' && price && quantity?(<><div className="invoice-products">
												<span class="heading">Order Summary</span>
												<table className="mt-2">
													<tr>
														<td>Total:</td>
														<td>${price}</td>
													</tr>
												</table>
												</div>	</>):(<></>)
											}
											<h3 class="text-light-ben py-4">
											<strong class="fw-bold">Payment Method:</strong>
											</h3>
											<div class="row">
												<div class="col">
													<label class="payments transition d-block text-center">
														<input
															type="radio"
															name="payment"
															value="visa"
															class="d-none"
															checked={selectedPayment === 'visa'}
															onChange={handlePaymentChange}
														/>
														<img
															src={visa}
															alt="Visa Master Card"
															class="img-fluid"
														/>
													</label>
												</div>
												<div class="col">
													<label class="payments transition d-block text-center">
														<input
															type="radio"
															name="payment"
															value="bitcoin"
															class="d-none"
															checked={selectedPayment === 'bitcoin'}
															onChange={handlePaymentChange}
														/>
														<img
															src={bitcoin}
															alt="Bitcoin"
															class="img-fluid"
															width="25"
														/>
													</label>
												</div>
												<div class="col">
													<label class="payments transition d-block text-center">
														<input
															type="radio"
															name="payment"
															value="binance"
															class="d-none"
															checked={selectedPayment === 'binance'}
															onChange={handlePaymentChange}
														/>
														<img
															src={binance}
															alt="Binance"
															class="img-fluid"
														/>
													</label>
												</div>
											</div>
									
									<div class="mb-4 mt-4">
									<button name="btn" class="btn btn-primary-ben w-100 rounded-0 fw-bold p-3 text-uppercase"  disabled={!selectedPayment}>Proceed Checkout</button>
									</div>
									</form>
								</>)}
								
							</div>
							{/* <div class="col-md-6 ps-md-6 text-center pt-4">
								<img  src={solider} class="soldier img-fluid opacity-75" alt="Soldier" width="220" />
								<strong class="d-block text-light-ben pt-5 lh-150">Fast and Easy Delivery - Multiple Delivery Methods</strong>
							</div> */}
							</div>
						</section>
						</div>
						<div class="col-lg-2 col-md-1"></div>
					</div>
					</div>
				</div>
		</>
		
	
	);

}
export default Checkout;